import React from "react"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import Paragraph from "../../../../components/paragraph"
import { getCourseNavigation } from "../../../../store/courses"
import BulletList from "../../../../components/bullet-list"
import Bullet from "../../../../components/bullet"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/jahreszeiten/02-eigenes-gedicht/thema-waehlen"
        />
      }
      navigation={navigation}
    >
      <Seo title="Ein eigenes Jahreszeitengedicht schreiben" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 2
            </Heading>
            <Heading as="h2" level={2}>
              Ein eigenes Jahreszeitengedicht schreiben
            </Heading>
          </Stack>
          <Paragraph>
            Nun hast du schon viel über Hölderlins Gedicht zum Frühling gelernt.
            Zeit, ein eigenes Jahreszeitengedicht zu verfassen! Die folgenden
            Schritte helfen dir dabei.
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>Ein Thema auswählen</Bullet>
            <Bullet>Ideen sammeln</Bullet>
            <Bullet>Reim & Klang</Bullet>
            <Bullet>Eine Form finden</Bullet>
            <Bullet>Das Gedicht ordnen</Bullet>
          </BulletList>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
